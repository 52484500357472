<form #formulario="ngForm" autocomplete=off (ngSubmit)="onSubmit(formulario)">

  <!-- Componente para título da aplicação -->
  <app-titulo-aplicacao
    texto="MP Atende - Atendimento à População">
  </app-titulo-aplicacao>

  <!-- Tela inicial -->
  <!-- <div id="inicio" [style.display]="b0"> -->

    <!-- Componente para links -->
    <mat-card class="home mat-elevation-z3"><app-links></app-links></mat-card>

    <br>

    <div id="inicio" *ngIf = "p[0]">

    <!-- Componente para denúncia eleitoral -->
    <!-- <mat-card class="home mat-elevation-z3"><app-denuncia-eleitoral></app-denuncia-eleitoral></mat-card>

    <br> -->

    <!-- Componente para links -->
    <!-- <mat-card class="home mat-elevation-z3"><app-links></app-links></mat-card>

    <br> -->

    <!-- Componente para aviso de funcionamento -->
    <app-mensagem-funcionamento *ngIf = "p[11]"></app-mensagem-funcionamento>

    <br>

    <mat-card class="home mat-elevation-z3">
      <mat-card-content>

        <!-- Componente para selecionar cidade -->
        <app-selecionarcidade
          texto = "A situação que você quer relatar ocorreu (ou está ocorrendo) em qual cidade do estado do Paraná? Se o fato não ocorreu no estado do Paraná, procurar o Ministério Público do estado em que ocorreu o fato."
          (selecionado)="selecionado($event)">
        </app-selecionarcidade>

        <br>

        <!-- Tipo do sigilo -->
        <!-- <label for="radioidentificado">Deseja identificar-se?</label><br> -->
        <label for="radioidentificado" class="required">Escolha o tipo de atendimento:</label><br>
        <mat-radio-group class="radio-group" (change)="radioButtonGroupIdentificado($event)" color=primary size="50%"
          name="radioidentificado" id="radioidentificado" [(ngModel)]=atendimento.pessoa.identificado [disabled]="d0">
          <mat-radio-button class="radio-button" value="1">
            ATENDIMENTO IDENTIFICADO<br>
            Seus dados cadastrais são obrigatórios e poderão ser encaminhados a outras unidades do
            Ministério Público do Paraná ou a outros órgãos públicos para apurar os fatos.
          </mat-radio-button>
          <mat-radio-button class="radio-button" value="2">
            ATENDIMENTO SIGILOSO<br>
            <span class="red">Atencão!</span> Seus dados cadastrais são obrigatórios e ficarão acessíveis à unidade do
            Ministério Público do Paraná que analisará o atendimento.
           </mat-radio-button>
          <mat-radio-button class="radio-button" value="3">
            ATENDIMENTO ANÔNIMO<br>
            Seus dados cadastrais não são obrigatórios.
          </mat-radio-button>
        </mat-radio-group>

      </mat-card-content>
    </mat-card>
  </div>

  <!-- Dados Cadastrais -->
  <div id="dadosCadastrais" class="dadosCadastrais" *ngIf = "p[1]">
    <mat-card class="home mat-elevation-z3">
      <mat-card-content>

        <mat-card-title class="title">Dados cadastrais</mat-card-title><br>
        <mat-card-subtitle class="red">Informe seus dados exatamente como constam nos documentos oficiais.</mat-card-subtitle>

        <!-- Nome -->
        <label for="nome" class="required">Nome:</label><br>
        <mat-form-field appearance="outline">
          <mat-label>Nome</mat-label>
          <input #nome="ngModel" matInput type="text" size="50" name="nome" id="nome" placeholder="sua resposta (obrigatório)"
          [(ngModel)]=atendimento.pessoa.nome required = false ngModel>
          <button *ngIf="atendimento.pessoa.nome" matSuffix mat-icon-button aria-label="Clear"
          (click)="atendimento.pessoa.nome=''">
          <mat-icon>close</mat-icon>
        </button>
        <div class = "msg-validador" *ngIf = "nome.value == '' && nome.touched">Obrigatório informar</div>
        </mat-form-field>

        <br>

        <!-- Nome Social -->
        <label for="nomeSocial">Nome social: designação pela qual a pessoa transgênero se identifica
          e é socialmente reconhecida<br>(Resolução nº 232, de 16 de junho de 2021, CNMP)</label><br>
        <mat-form-field appearance="outline">
          <mat-label>Nome social</mat-label>
          <input matInput type="text" size="50" name="nomeSocial" id="nomeSocial" placeholder="sua resposta"
            [(ngModel)]=atendimento.dadosComplementares.nomeSocial ngModel>
          <button *ngIf="atendimento.dadosComplementares.nomeSocial" matSuffix mat-icon-button aria-label="Clear"
            (click)="atendimento.dadosComplementares.nomeSocial=''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <br>

        <!-- Componente para selecionar sexo -->
        <app-selecionar-sexo
           texto="Sexo" (sexoSelecionado)="sexoSelecionado($event)">
        </app-selecionar-sexo>

        <!-- Checkbox de seleção de país -->
        <!-- <div>
          Estrangeiro
          <input type="checkbox" name="ehestrangeiro" id="ehestrangeiro" (change)="habilitaPais($event)" ngModel>
        </div>

        <br> -->

        <!-- Componente para selecionar país -->
        <!-- <div *ngIf = "p[12]"> -->
        <div *ngIf = "true">
          <app-selecionar-pais
            texto="Nacionalidade:" (paisSelecionado)="paisSelecionado($event)">
          </app-selecionar-pais>
        </div>

        <!-- Digitar CPF -->
        <label for="numCpf" >CPF:</label><br>
        <mat-form-field appearance="outline">
          <mat-label>CPF</mat-label>
          <!-- <input #numCPF="ngModel" matInput type="text" size="20" name="numCpf" id="numCpf" mask="000.000.000-00" [disabled]="d3" placeholder="sua resposta (obrigatório)"
          [(ngModel)]=atendimento.dadosComplementares.numCpf maxlength="14" required = !flagNaoInformaCpf (keyup)="cpfDigitado($event)" ngModel>
          <button
          *ngIf="atendimento.dadosComplementares.numCpf" matSuffix mat-icon-button aria-label="Clear"
          (click)="atendimento.dadosComplementares.numCpf=''"> -->
          <input #numCPF="ngModel" matInput type="text" size="20" name="numCpf" id="numCpf" mask="000.000.000-00" [disabled]="d3" placeholder="sua resposta (obrigatório)"
          [(ngModel)]=atendimento.dadosComplementares.numCpf maxlength="14" (keyup)="cpfDigitado($event)" ngModel>
          <button
          *ngIf="atendimento.dadosComplementares.numCpf" matSuffix mat-icon-button aria-label="Clear"
          (click)="atendimento.dadosComplementares.numCpf=''">
          <mat-icon>close</mat-icon>
        </button>
          <div class = "msg-validador" *ngIf = "numCPF.value == '' && numCPF.touched && !flagNaoInformaCpf">Obrigatório informar</div>
          <div class = "msg-validador" *ngIf = "numCPF.value != '' && !flagCpfValido">CPF Inválido</div>
          <!-- <div class = "msg-validador" *ngIf = "numCPF.value != '' && numCPF.value != null">CPF menor que 11</div> -->
        </mat-form-field>
        &nbsp;&nbsp;&nbsp;
        <input type="checkbox" name="informaRg" id="informaRg" [(ngModel)] = flagNaoInformaCpf (change)="habilitaCampoRg($event)" ngModel>
        Não Informado.

        <div class="groupRg" *ngIf = "p[7]">
          <!-- Número do RG -->
          <div id="dadosRg" class="dadosRg">
            <label for="rg">Número do seu RG:</label><br>
            <mat-form-field appearance="outline">
              <mat-label>RG</mat-label>
              <!-- <input #numRg = "ngModel" matInput type="rg" size="20" name="rg" id="rg" mask="AAAAAA || AAAAAAA || AAAAAAAA || AAAAAAAAA || AAAAAAAAAA" [disabled]="d4" placeholder="0000000000"
              [(ngModel)]=atendimento.dadosComplementares.numRg required = !flagNaoInformaRg ngModel> -->
              <input #numRg = "ngModel" matInput type="rg" size="20" name="rg" id="rg" mask="AAAAAA || AAAAAAA || AAAAAAAA || AAAAAAAAA || AAAAAAAAAA" [disabled]="d4" placeholder="0000000000"
              [(ngModel)]=atendimento.dadosComplementares.numRg required = false ngModel>
              <button
                *ngIf="atendimento.dadosComplementares.numRg"
                matSuffix mat-icon-button aria-label="Clear"
                (click)="atendimento.dadosComplementares.numRg=''">
                <mat-icon>close</mat-icon>
              </button>
              <div class = "msg-validador" *ngIf = "numRg.value == '' && numRg.touched && !flagNaoInformaRg">Obrigatório informar</div>
            </mat-form-field>
          </div>

          <!-- Não apagar este código!! -->
          <!-- Componente para selecionar estado do RG -->
          <!-- <div class="dadosRg" [style.display]="b7">
            <app-selecionar-estado
               texto="Informe o estado do seu RG:" (estadoSelecionado)="estadoSelecionado($event)">
            </app-selecionar-estado>
          </div> -->

          <!-- Selecionar estado -->
          <div class="dadosRg">
            <label for="estado">Estado do seu RG:</label><br>
            <mat-form-field appearance="outline">
              <mat-label>Estado</mat-label>
              <!-- <mat-select #estado="ngModel" name="estado" id="estado" [disabled]="d4" [(ngModel)]=atendimento.dadosComplementares.ufRg required=!flagNaoInformaRg ngModel> -->
              <mat-select #estado="ngModel" name="estado" id="estado" [disabled]="d4" [(ngModel)]=atendimento.dadosComplementares.ufRg required=false ngModel>
                <mat-option value="">Selecione...(obrigatório)</mat-option>
                <mat-option *ngFor="let estado of estadosOptions | keyvalue" value={{estado.value}}>{{estado.key}} </mat-option>
              </mat-select>
              <div class = "msg-validador" *ngIf = "estado.value == '' && estado.touched && !flagNaoInformaRg">Obrigatório informar</div>
            </mat-form-field>
          </div>

          <!-- Checkbox de RG não informado-->
          <div class="dadosRg">
            <br><br>
            <input type="checkbox" name="naoInformaRg" id="naoInformaRg" (change)="desabilitaCampoRg($event)" ngModel>
            Não Informado.
          </div>
        </div>

        <!-- Data de nascimento -->
        <div>
          <label for="dataNascimento" class="required">Data de nascimento:</label><br>
          <mat-form-field appearance="outline">
            <mat-label>Data de nascimento</mat-label>
            <input #dataNascimento = "ngModel" matInput [matDatepicker]="picker" name="dataNascimento" id="dataNascimento" data-mask="00/00/0000"
             maxlength="10" placeholder="DD/MM/AAAA" [(ngModel)]=atendimento.pessoa.dataNascimento required=false ngModel>
             <!-- <mat-hint>DD/MM/AAAA</mat-hint> -->
             <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
             <mat-datepicker touchUi #picker></mat-datepicker>
             <button *ngIf="atendimento.pessoa.dataNascimento" matSuffix mat-icon-button aria-label="Clear"
               (click)="atendimento.pessoa.dataNascimento=''">
               <mat-icon>close</mat-icon>
             </button>
             <div class = "msg-validador" *ngIf = "dataNascimento.value == '' && dataNascimento.touched">Obrigatório informar</div>
          </mat-form-field>
        </div>

        <!-- Nome da mãe-->
        <label for="nomeMae" class="required">Nome da mãe:</label><br>
        <mat-form-field appearance="outline">
          <mat-label>Nome da mãe</mat-label>
          <input #nomeMae="ngModel" matInput type="text" size="50" name="nomeMae" id="nomeMae" placeholder="sua resposta (obrigatório)"
          [(ngModel)]=atendimento.pessoa.nomeMae required=false ngModel>
          <button *ngIf="atendimento.pessoa.nomeMae" matSuffix mat-icon-button aria-label="Clear"
          (click)="atendimento.pessoa.nomeMae=''">
          <mat-icon>close</mat-icon>
        </button>
        <div class = "msg-validador" *ngIf = "nomeMae.value == '' && nomeMae.touched">Obrigatório informar</div>
        </mat-form-field>

        <br>

        <!-- Componente para selecionar cidade e estado -->
        <app-selecionarestadocidade
           texto="Estado e cidade de moradia:"
           (ufSelecionada)="ufSelecionada($event)"
           (cidadeSelecionada)="cidadeSelecionada($event)">
        </app-selecionarestadocidade>

        <br>

        <!-- Telefone -->
        <label for="telefone" class="required">Número do seu telefone com DDD:</label><br>
        <mat-form-field appearance="outline">
          <mat-label>Telefone</mat-label>
          <input #telefone="ngModel" matInput type="tel" size="30" name="telefone" id="telefone" mask="(00)0000-0000) || (00)00000-0000"
          placeholder="(00)0000-0000 (obrigatório)" [(ngModel)]=atendimento.dadosComplementares.telefone required=false
          ngModel>
          <button
             *ngIf="atendimento.dadosComplementares.telefone" matSuffix mat-icon-button aria-label="Clear"
            (click)="atendimento.dadosComplementares.telefone=''">
            <mat-icon>close</mat-icon>
          </button>
          <div class = "msg-validador" *ngIf = "telefone.value == '' && telefone.touched">Obrigatório informar</div>
        </mat-form-field>

        <br>

        <!-- Email com ngModel (não apagar este código, para futuras referências)-->
        <!-- <label for="email">Email:</label><br>
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input #email="ngModel" matInput type="email" size="50" name="email" id="email" placeholder="nome@email.com (obrigatório)"
          [(ngModel)]=atendimento.dadosComplementares.email required email ngModel>
          <button
            *ngIf="atendimento.dadosComplementares.email" matSuffix mat-icon-button aria-label="Clear"
            (click)="atendimento.dadosComplementares.email=''">
            <mat-icon>close</mat-icon>
          </button>
          <div class = "msg-validador" *ngIf = "email.value == '' && email.touched">Obrigatório informar</div>
          <div class = "msg-validador" *ngIf = "email.value != '' && email.invalid">E-mail inválido</div>
        </mat-form-field> -->

        <!-- Email com função validadora-->
        <label for="email" class="required">Email:</label><br>
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input #email="ngModel" matInput type="email" size="50" name="email" id="email" placeholder="nome@email.com (obrigatório)"
          [(ngModel)]=atendimento.dadosComplementares.email required=false (keyup)="emailDigitado($event)" ngModel>
          <button
            *ngIf="atendimento.dadosComplementares.email" matSuffix mat-icon-button aria-label="Clear"
            (click)="atendimento.dadosComplementares.email=''">
            <mat-icon>close</mat-icon>
          </button>
          <div class = "msg-validador" *ngIf = "email.value == '' && email.touched">Obrigatório informar</div>
          <div class = "msg-validador" *ngIf = "email.value != '' && !flagEmailValido">E-mail inválido</div>
        </mat-form-field>
        <br> O e-mail informado será usado para contato.
        <br><br>

        <!-- <button mat-raised-button color="primary" id="confirmaDados" (click)="confirmaDados()">Confirmar dados</button> -->

      </mat-card-content>
    </mat-card>
  </div>

  <!-- Confirmar anonimato (opção 3)-->
  <div id="perguntaConfirmaAnonimato" class="perguntaConfirmaAnonimato" *ngIf = "p[2]">
    <mat-card class="home mat-elevation-z3">
      <mat-card-content>
        <mat-card-title class="title">Confirmação</mat-card-title><br>
        <section class="section">
        <div id="textoConfirmaAnonimato">
          <b>Atenção!</b> A opção pelo Anonimato impossibilitará que o <b>Ministério Público</b> retorne
          contato para solicitar eventual complemento das informações prestadas, o que poderá
          inviabilizar a análise da questão e o prosseguimento da demanda.
        </div>
        <br>
        <mat-checkbox class="required" name="cienciaAnonimato" id="cienciaAnonimato" color=primary #cienciaAnonimato (change)="checkButtonCienciaAnonimato($event)">
          Estou ciente.
        </mat-checkbox>
        </section>
        <br>
        <label for="radiocontinuaanonimo">
          <h4 class="required">Deseja continuar anônimo?</h4>
        </label>
        <mat-radio-group class="radio-group" (change)="radioButtonGroupAnonimo($event)" color=primary
          name="radiocontinuaanonimo" id="radiocontinuaanonimo" [disabled]="d1">
          <mat-radio-button class="radio-button" value="1">Sim</mat-radio-button>
          <mat-radio-button class="radio-button" value="2">Não</mat-radio-button>
        </mat-radio-group>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- Confirmar semSigilo (opção 1)-->
  <div id="perguntaConfirmaSemSigilo" class="perguntaConfirmaSemSigilo" *ngIf = "p[8]">
   <mat-card class="home mat-elevation-z3">
     <mat-card-content>
       <mat-card-title class="title">Confirmação</mat-card-title><br>
         <div id="textoConfirmaSemSigilo"  class="required">
           <strong>Nos termos da Lei Geral de Proteção de Dados - </strong><span class = "red">Lei nº 13.709/2018</span><strong>, concorda com o encaminhamento?</strong>
         </div>
       <br>
       <mat-radio-group class="radio-group" (change)="radioButtonGroupSemSigilo($event)" color=primary
         name="radioConfirmaSemSigilo" id="radioConfirmaSemSigilo">
         <mat-radio-button class="radio-button" value="1">Sim</mat-radio-button>
         <mat-radio-button class="radio-button" value="2">Não</mat-radio-button>
       </mat-radio-group>
     </mat-card-content>
   </mat-card>
  </div>

  <!-- Escolha do tipo do sigilo -->
  <div id="indicatiposigilo" *ngIf = "p[5]">
    <mat-card class="home mat-elevation-z3">
      <mat-card-content>
        <label for="radiotiposigilo">Escolha o tipo de atendimento:</label><br><br>
          <mat-radio-group class="radio-group" name="radiotiposigilo" id="radiotiposigilo" (change)="radioButtonGroupSigilo($event)" color=primary size="50%"
          [(ngModel)]=atendimento.pessoa.identificado>
            <mat-radio-button class="radio-button" value="1">
              ATENDIMENTO IDENTIFICADO<br>
              Seus dados cadastrais são obrigatórios e poderão ser encaminhados a outras unidades do
              Ministério Público do Paraná ou a outros órgãos públicos para apurar os fatos.
            </mat-radio-button>
            <mat-radio-button class="radio-button" value="2">
              ATENDIMENTO SIGILOSO<br>
              <span class="red">Atencão!</span> Seus dados cadastrais são obrigatórios e ficarão acessíveis à unidade do
              Ministério Público do Paraná que analisará o atendimento.
            </mat-radio-button>
          </mat-radio-group>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- Descrição do motivo, anexar arquivo, número do atendimento anterior -->
  <div id="descricao" *ngIf = "p[3]">
    <mat-card class="home mat-elevation-z3">
      <mat-card-content>
        <mat-card-title class="title">Descrição da Solicitação</mat-card-title><br>
        <!-- Descrição do motivo -->
        <!-- <label for="descricao">Descreva aqui o motivo do seu contato com o maior número de informações,
          para melhor auxílio pela equipe do MP Atende.<br>
          Por exemplo: Quando o fato ocorreu, local ou endereço e o que aconteceu.</label> -->
        <div class = "textoDescricao" >
          Descreva aqui o motivo do seu contato com o maior número de informações,
          para melhor atendimento pelo Ministério Público do Paraná.
          Por exemplo: Quando o fato ocorreu, local ou endereço e o que aconteceu.
        </div>
        <div class = "textoDescricao" *ngIf = "atendimento.pessoa.identificado == 2">
          Você optou pelo <b>atendimento sigiloso</b>, portanto <b class="required" >não informe dados que possibilitem sua identificação no campo abaixo.</b>
        </div>
        <div class = "textoDescricao" *ngIf = "atendimento.pessoa.identificado == 3">
          Você optou pelo <b>atendimento anônimo</b>, portanto <b class="required" >não informe dados que possibilitem sua identificação no campo abaixo.</b>
        </div>

        <br>

        <mat-form-field class="descricao" appearance="outline">
          <mat-label>Solicitação</mat-label>
          <!-- <textarea
             matInput #descricao id="descricao" name="descricao" (input)="habilitaBotaoEnviar($event)" rows="10" cols="100"
             [(ngModel)]=atendimento.atendimento.descricao maxlength="4000">
          </textarea> -->
          <textarea
          matInput #descricao id="descricao" name="descricao" rows="10" cols="100"
          [(ngModel)]=atendimento.atendimento.descricao maxlength="3900">
          </textarea>
          <mat-hint align="start" class = "msg-validador" *ngIf = "!validar.contadorMinimoPalavras(descricao.value)"><strong>Mínimo de palavras : 3&emsp;</strong></mat-hint>
          <mat-hint align="start" class = "msg-validador" *ngIf = "descricao.value.length == 3900"><strong>Atingiu o limite máximo de caracteres</strong></mat-hint>
          <mat-hint align="end"><strong>Máximo de caracteres : {{ descricao.value.length }}/3900</strong></mat-hint>
         </mat-form-field>

        <br><br>

        <!-- Componente para anexar arquivo -->
        <app-anexar-arquivo
           [registroAtual]="atendimento" (registroAlterado)="anexos($event)">
        </app-anexar-arquivo>

        <br><br>

        <!-- Número do atendimento anterior -->
        <label for="numAtendAnterior">Caso já tenha procurado o Ministério Público do Paraná para relatar esta situação,
          por favor, informe o número de atendimento abaixo.</label><br>
        <mat-form-field class="numAtendAnterior" appearance="outline">
          <mat-label>Protocolo</mat-label>
          <input matInput type="text" mask="0000.00.000000-0" name="numAtendAnterior" id="numAtendAnterior"
            placeholder="0000.00.000000-0" [(ngModel)]=atendimento.atendimento.numAtendAnterior>
        </mat-form-field>

        <br>

        <!-- <button mat-raised-button id="criarAtendimento" (click)="criarAtendimento()" color="primary" [disabled]="d2">Enviar</button> -->
      </mat-card-content>
    </mat-card>

  </div>

  <h4 class="spinnerSection" *ngIf="progressVisible">Aguarde, enviando atendimento...</h4>
  <div class="spinnerSection" *ngIf="progressVisible">
    <!-- <label>Aguarde, enviando atendimento...</label> -->
    <!-- <mat-spinner></mat-spinner> -->
      <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
    <!-- <mat-progress-bar mode="buffer" color="warn"></mat-progress-bar> -->
  </div>

  <!-- Componente para pesquisa-->
  <div id="pesquisa" name="pesquisa" [style.display]="b8">
    <app-pesquisa-satisfacao
      [registroAtual]="atendimento">
    </app-pesquisa-satisfacao>
  </div>

  <!-- Componente para resumo do atendimento -->
  <div id="resumoatendimento" name="resumoatendimento" *ngIf = "p[4]">
    <app-dados-atendimento
      texto="Resumo da Solicitação" [atendimento]="atendimento" tipo="resumo">
    </app-dados-atendimento>
  </div>

  <!-- Pesquisa de satisfação -->
   <div id="resumoatendimento" name="resumoatendimento" *ngIf = "p[4] == true" [style.display]="b9">
    <br>
    <button mat-raised-button id="telaPesquisa" (click)="telaPesquisa()" color="primary" >Responder pesquisa de satisfação</button>
  </div>

  <!-- <div>
    <br>
    <button mat-raised-button type="submit" color="primary">Log do formulário (para testes somente)</button>
  </div> -->

  <!-- Componente para mensagem de recesso -->
  <app-mensagem-recesso *ngIf = "p[9]" (cienciaRecesso)="cienciaRecesso($event)"></app-mensagem-recesso>

  <!-- Componente para mensagem de indisponibilidade -->
  <app-mensagem-indisponibilidade *ngIf = "p[10]"></app-mensagem-indisponibilidade>

  <div class="button-row">
    <button mat-raised-button color="primary" *ngIf = "mbv == true" [disabled]="!hbv" (click)="contexto(atu,1)">Voltar</button>
    <button mat-raised-button color="primary" *ngIf = "mba == true" [disabled]="!hba" (click)="contexto(atu,2)">Avançar</button>
  </div>
  <div id="atencao" *ngIf = "p[0]">
    <app-mensagem-atencao></app-mensagem-atencao>
  </div>
  <!-- <div *ngIf = "p[9] || p[0]">
    <a href="https://mppr.mp.br/Pagina/MP-Atende-Tira-duvidas-FAQ"><b>Tira dúvidas</b></a>&nbsp;&nbsp;&nbsp;
    <a href="https://mppr.mp.br/Pagina/MP-Atende-Consulta-protocolo"><b>Consulta protocolo</b></a>
  </div> -->

</form>
