<mat-card class="home mat-elevation-z3">
  <mat-card-title>ATENÇÃO:</mat-card-title><br>

  <div class = "textoDescricao">

  Em razão do período de <b>recesso forense</b>, compreendido entre <b>20 de dezembro
  de 2024 e 06 de janeiro de 2025</b>
  (Resolução nº 9643/2024 do Procurador-Geral de Justiça do Estado do Paraná),
  os serviços do Ministério Público do Estado do Paraná estarão em regime de plantão.

  <br><br>

  Para atendimento, acesse os seguintes canais do MPPR:

  <br><br>

  <!-- - <a href="https://mppr.mp.br/modules/conteudo/conteudo.php?conteudo=8353"> -->
  - <a href="https://mppr.mp.br/Pagina/Plantao-de-atendimento-populacao" target="_blank">
  <b>Plantão de atendimento à população</b></a>:
  telefone (41) 99108-8101 ou e-mail <a href="mailto:plantao@mppr.mp.br"><b>plantao@mppr.mp.br</b></a> (em <b>situações de urgência</b>);<br>

  - <b>Promotorias de Justiça:</b> localize
  <!-- <a href="http://www.mppr.mp.br/modules/conteudo/conteudo.php?conteudo=7385"><b>aqui</b></a> -->
  <a href="https://mppr.mp.br/Encontre-uma-promotoria-de-justica?conteudo=7385" target="_blank"><b>aqui</b></a>
  uma Promotoria de Justiça da sua região;<br>

  - <b>Central de Atendimento ao Cidadão:</b> localize
  <!-- <a href="https://hotsite.mppr.mp.br/modules/conteudo/conteudo.php?conteudo=3286"><b>aqui</b></a> -->
  <!-- <a href="https://hotsite.mppr.mp.br/modules/conteudo/conteudo.php?conteudo=3286" target="_blank"><b>aqui</b></a> -->
  <a href="https://site.mppr.mp.br/atendimento/Pagina/Centrais-de-Atendimento" target="_blank"><b>aqui</b></a>
  a Central de Atendimento mais próxima de seu endereço.

  <br><br>

  <!-- A análise e encaminhamentos dos formulários eletrônicos (MP Atende) recebidos no período -->
  <!-- de recesso, serão realizados após o dia <b>06 de janeiro de 2025.</b> -->

  <!-- <br><br> -->

  Caso tenha interesse em conhecer as áreas de atuação do MPPR,
  <!-- clique em <a href="https://hotsite.mppr.mp.br/modules/conteudo/conteudo.php?conteudo=3299"><b>áreas de atuação.</b></a> -->
  <!-- clique em <a href="https://hotsite.mppr.mp.br/modules/conteudo/conteudo.php?conteudo=3299" target="_blank"><b>áreas de atuação.</b></a> -->
  clique em <a href="https://site.mppr.mp.br/atendimento/Pagina/Areas-de-Atuacao" target="_blank"><b>áreas de atuação.</b></a>
  Disponibilizamos, ainda, conteúdos que visam informar a população sobre
  <!-- seus <a href="https://hotsite.mppr.mp.br/modules/conteudo/conteudo.php?conteudo=3300"><b>direitos.</b></a> -->
  <!-- seus <a href="https://hotsite.mppr.mp.br/modules/conteudo/conteudo.php?conteudo=3300" target="_blank"><b>direitos.</b></a> -->
  seus <a href="https://site.mppr.mp.br/atendimento/Pagina/Conheca-seus-Direitos" target="_blank"><b>direitos.</b></a>

  <br><br>

  Atenciosamente,<br>
  Coordenadoria da Política Estadual de Atendimento ao Público<br>
  Ministério Público do Estado do Paraná<br>
  <!-- <a href="http://www.mppr.mp.br/"><b>http://www.mppr.mp.br/</b></a> -->
  <a href="https://mppr.mp.br"  target="_blank"><b>https://mppr.mp.br</b></a>

  </div>

  <br>

  <!-- <button mat-raised-button color="primary" (click)="itemCienciaRecesso('')">Estou ciente</button> -->
</mat-card>
